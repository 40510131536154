.modalBackground {
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(200, 200, 200); */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  color: white;

}

.modalContainer {
  width:60vw;
  max-width: 1000px;
  height: max-content;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;

}


.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  text-align: center;
  position: relative;


  padding: 0;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

.outerSpan{
  color:black;
  font-weight: 600;
}
.innerSpan{
  color: red;
  font-weight: 600;
}

#cancelBtn {
  background-color: crimson;
}

.modal-row1{
  background-image: url(../../assets/img/nnnn.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  border-top-left-radius:12px ;
  border-bottom-left-radius:12px ;
}

.popindetails h4{
font-weight: 600;
}

.popindetails span{
  font-size: 18px;
}

.popindetails small{
  font-size: 14px;
 
}

.popindetails img{
  width: 50px;
  border-radius: 50%;
}
.modal-row2{

  font-size: 16px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: white;
  padding: 30px;
  
}

.modal-row2 h4{
  color: black;
  font-weight: 600;
}
.modal-row2 .form-group{
color: black;
text-align: left !important;
margin-bottom: 10px;
}

.modal-close-button{
  position: absolute;
  right: 2px;
  top: 2px;
 background: transparent;
  border: none;
  outline: none;
}


.modal-row2 .form-group label{
font-weight: 600;
  }

  .modal-row2-button{
background-color: #008bd0;
color: white;
width:200px;
padding:8px;
border: none;
outline: none;
  }

  .form-error{
    color: red;
    font-size: 13px;
  }

  .custom-input-class{
    margin-right: 0;
    padding: 0;
  }

  input.form-control.custom-input-class {
    width: 100% !important;
  border:0.5px solid rgb(232, 227, 227);

}

  @media only screen and (max-width:991px){
    .popindetails span{
      font-size: 16px;
    }
    
    .popindetails small{
      font-size: 13px;
     
    }

    .modalContainer {
      width:80vw;
    
    
    }
  }

  @media only screen and (max-width:767px){
    .popindetails span{
      font-size: 14px;
    }
    
    .popindetails small{
      font-size: 12px;
     
    }

    .modalContainer {
      width:80vw;
    
    
    }

    .modalContainer .body {
   background: transparent;
   overflow-x: scroll;
    }

    .modal-row1{
      background-image: url(../../assets/img/popimagenew.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 200px;
      padding: 20px;
     border-radius: 0;
     display: none;
    }

    .modal-row2{
  
    border-radius: 0;
  
      
    }


  }

  @media only screen and (max-width:567px){
    .popindetails span{
      font-size: 13px;
    }
    
    .popindetails small{
      font-size: 11px;
     
    }

    .modalContainer {
      width:90vw;
    
    
    }
  }