.newhero
{
   
    height: 600px;
    background-image: url("../../../assets/img/bannerhero.jpg"), url("../../../assets/img/bannerhero3.png"), url("../../../assets/img/bannerhero2.png");
    background-size: cover;
    background-position: center right;
  padding-top: 80px;
  animation: shuffle 15s infinite ease;
    transition: all 15s ease;
    position: relative;
}

.hero__link{
  display: flex;
  align-items: center;
  position: absolute;
  top: 430px;
  z-index: 1;
 
}

.hero__span{
  color: white;
  font-size: 16px;
  animation: shuffle 15s infinite ;
  transition: all 15s infinite;
}

.hero__sublink{
border: 1px solid white;
  padding: 5px 10px;
  border-radius: 17px;
  margin-left: 10px;
  text-decoration: none;
  color: white;
  font-size: 13px;
  animation: shuffle 15s infinite ease;
  transition: all 15s ease;
  z-index:111;
  position: relative;
 
}

.hero__sublink:hover{
background-color: white;
color: #424242;
}

@keyframes shuffle {
    0% { background-image:  url("../../../assets/img/bannerhero.jpg"); color: white; }
    33% { background-image: url("../../../assets/img/bannerhero3.png");  color: black; }
    66% { background-image: url("../../../assets/img/bannerhero2.png"); color: white;}
    100% { background-image: url("../../../assets/img/bannerhero.jpg");   color: white; }

    
  }
  

  
@-moz-keyframes shuffle {
    0% { background-image:  url("../../../assets/img/bannerhero.jpg"); color: white;transition: all 15s ease; }
    33% { background-image: url("../../../assets/img/bannerhero3.png");  color: black; transition: all 15s ease;}
    66% { background-image: url("../../../assets/img/bannerhero2.png"); color: white;transition: all 15s ease;}
    100% { background-image: url("../../../assets/img/bannerhero.jpg");   color: white; transition: all 15s ease;}
    
  }


.searchbar{
    margin-top: 8%;
}


.middle_img{
    height: 300px;
    width: 300px;
border-radius: 50%;
position: absolute;
right: 15%;
border: 15px solid rgb(255, 255, 255);
top:25% ;
object-fit: cover;
object-position: center;
}

.smallimage1{
    height: 60px;
    width: 60px;
    position: absolute;
    border-radius: 50%;
right: 44%;
top: 50%;
border: 2px solid #dc8788;
}

.smallimage2{
    height: 60px;
    width: 60px;
    position: absolute;
    border-radius: 50%;
right: 39%;
top: 25%;
border: 2px solid #dc8788;
}

.smallimage3{
    height: 60px;
    width: 60px;
    position: absolute;
    border-radius: 50%;
right: 15%;
top: 60%;
border: 2px solid #dc8788;
}

.smallimage4{
    height: 60px;
    width: 60px;
    position: absolute;
    border-radius: 50%;
right: 15%;
top: 15%;
border: 2px solid #dc8788;
}

.smallimage5{
    height: 55px;
    width: 55px;
    position: absolute;
    border-radius: 50%;
right: 32%;
top: 60%;
border: 2px solid #dc8788;
background-color: white;
box-shadow: 0px 0px 2px 2px #dc8788;
}

@media only screen and (min-width:991px) and (max-width:1199px){
  .hero__link{
    flex-direction: row;
    gap: 10px;
    top: 67%;
 
  }
  
}



@media only screen and (max-width:991px) {
    .newhero
{
    background-position: center center;
  padding-top: 80px;

}

.hero__link{
  flex-direction: row;
  gap: 10px;
  top: 60%;
  left: 20%;
}

.searchbar{
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.search-box{
    position: static;
}
}

@media only screen and (max-width:767px){
  .hero__link{
    flex-direction: column;
    gap: 10px;
    top: 73%;
left: 25%;
    z-index:0;
  }
  .relative__div{position: relative;}
  .relative__divrow {
    position: absolute;
    z-index: 111;
    width: 100%;
    max-height: 240px;
    text-align: left;
    margin-top: -9px;
    border: 0;
  }
}

@media only screen and (max-width:667px){
  .hero__link{
    flex-direction: column;
    gap: 10px;
    top: 73%;
    left: 20%;
    z-index:0;
  }
  .relative__div{position: relative;}
  .relative__divrow {
    position: absolute;
    z-index: 111;
    width: 100%;
    max-height: 240px;
    text-align: left;
    margin-top: -9px;
    border: 0;
  }
}

@media only screen and (max-width:567px){
  .hero__link{
    flex-direction: column;
    gap: 10px;
    top: 73%;
    left: 10%;
    z-index:0;
  }
  .relative__div{position: relative;}
  .relative__divrow {
    position: absolute;
    z-index: 111;
    width: 100%;
    max-height: 240px;
    text-align: left;
    margin-top: -9px;
    border: 0;
  }
}
@media only screen and (max-width:467px){
  .hero__sublink{padding: 5px;

}

  .hero__link{
    flex-direction: column;
    gap: 10px;
    top: 73%;
    left: 4%;
    z-index:0;
  }
}

