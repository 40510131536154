.commondisplayflex{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}

.landingnav{
    background-color: #04334b;
}

.landingpageback {
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
}

.landingpageback h2{
    font-weight: 600;
    font-size: 25px;
}

.landingpageback p{
    font-weight: 600;
    font-size: 22px;
    color: black;
}

.youtube-icon{
    background-color: red;
    height:36px ;
    width: 36px;

   
    border-radius: 50%;
    padding: 10px;
}

.landing-top-menu{
display: flex;
justify-content: space-between;
align-items: center;
}

.landing-icons{
    display: flex;
    gap: 5px;
}

.landing-number{
    font-weight: 600;
}

.landing-form{
    border: 1px solid #008bd0;

}

.landing-form input{
    border: 1px solid #008bd0;
    margin-bottom:10px ;
    margin-top: 10px;
}

.landing-form textarea{
    border: 1px solid #008bd0;
    margin-bottom: 20px;
}

.landing-form .btn{
background-color: #e73c3f;
width: 130px;
}


.landing-form .btn:hover{
    background-color: #e73c3f;
    }

    .landing-main{
        color: white;
        display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height: 100%;
text-align: left;

    }

    .landing-main h2{
        font-size: 25px;
    }


    .landing-main .free-consult{
        font-size: 15px;
     color: #e73c3f;
        font-size:22px;

    }

    
    .landing-main p{
    color: white;
    margin-top: 20px;
    font-size:22px
    }

    .content{
        display: flex;


flex-direction: column;
padding: 30px;

max-width: 540px;
max-height: 600px;
overflow-y: hidden;
    }

    .content-icon{
        background-color: #008bd0;
        padding: 5px;
        width: 50px;
    }

    .landingpagecontent h4{
        font-weight: 600;
        margin-top: 10px;
        font-size: 20px;
    }
    
    .landingpagecontent span{
      font-size: 15px;
    }

    .landingpagecontent .btn{
        background-color:#e73c3f;
        color: white;
        width: 200px;
        margin: auto;
        border: none;
        border-radius: 0;
        margin-bottom: 30px;
        padding: 8px;
    }

    .image-div{
        text-align: end;
    }

    .landing-frame{
        height: 600px;
        border: 2px solid rgb(151, 48, 48);
        box-shadow: 0px 0px 1px 1px rgb(219, 208, 208);
    }

    .frame-text{
        color: white;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
    }

    .landing-logo{
        max-width: 230px;


        margin-bottom:20px;
    }

    .help-section{
        font-weight: 600 !important;
        font-size: 30px !important;
    }

    .landing_services h5{
        font-size: 18px;
        font-weight: 600;

    }

    .landing_services li{
        font-size: 15px;
     
        
    }

    .landing-box-service{
        background-color: #008bd0;
        border-radius: 15px;
        padding: 30px;
        height: 200px;
        color: white;
        margin-bottom: 30px;
        overflow: hidden;
    }

    @media only screen and (max-width:767px){
        .landing-icons{
            justify-content: center;
            margin-bottom: 20px;
        }

        .landingpageback{
            padding-top: 380px;
            background-position: top;
        
        }

        .landing-frame{
            height: 630px;
          
        }

        .image-div{
            text-align: center;
        }
 
    .frame-text{
     margin-top: 20px;
    }}

    @media only screen and (max-width:567px){
      
        .landing-main .free-consult{
            font-size:19px      
        }
    
        .landing-main p{
          color: white;
        font-size:19px
    }
    }

    
    
    @media only screen and (max-width:991px){
      
       
        .landing-frame{
            height: 630px;
    }
    }