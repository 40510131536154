.thankyou{
    margin-top: 100px;
   
    padding-bottom: 100px;
   }
   
   
   .thankyou-inner{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   background-image: url(../../assets/img/rm222batch5-kul-03.jpg);
   background-position: center center;
   background-repeat: no-repeat;
   background-size: cover;
   border-radius: 15px;
   margin-bottom: 60px;
   border: 1px solid #e5f1f8;
   padding: 100px  40px;
   
   }
   .thankyou span{
       color: #008bd0;
       font-weight: 700;
   }
   
   .thankyou-link{
       color: #008bd0;
   }





.errorPage{
 margin-top: 100px;

 padding-bottom: 100px;
}


.errorPage-inner{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background-image: url(../../assets/img/rm222batch5-kul-03.jpg);
background-position: center center;
background-repeat: no-repeat;
background-size: cover;

border-radius: 15px;
padding: 100px  40px;
margin-bottom: 60px;

}
.errorPage span{
    color: #e73c3f;
    font-weight: 700;
}

.errorPage-link{
    color: #e73c3f;
}



