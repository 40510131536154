.testimonials{
    margin: 10px 0px;
    background-color:  #e9f0ff;
}

.testimonial__heading{
    color: black; 
}




  .image-width{
    width: 30px;
   
    padding: 2px;
  }

  @media only screen and (max-width:767px){
    .resposive__user{
            margin-top: 50px;
    }
  }




/* 
  -------------------------------------------Other testimonial---------------------------------------------------------------- */

  .testhero{
    background-color: white;
     box-shadow: 4px 4px 4px 0px;
}


@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);
figure.snip1157 {
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  margin-top: 30px;
  min-width: 220px;
  max-width: 310px;
  width: 100%;
  color: white;
  text-align: left;
  box-shadow: none !important;
}
figure.snip1157 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}
figure.snip1157 img {
  max-width: 100%;
  vertical-align: middle;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  /* margin: 40px 0 0 10px; */
  visibility: hidden;
}
figure.snip1157 blockquote {
  display: block;
  border-radius: 8px;
  position: relative;
  background-color: #008bd0;
  padding: 25px 30px 15px 30px;
  font-size: .9rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.6em;
  height: 200px;
  font-family: 'Inter', sans-serif;
}
figure.snip1157 blockquote:before,
figure.snip1157 blockquote:after {
  font-family: 'FontAwesome';
  content: "\201C";
  position: absolute;
  font-size: 15px;
  opacity: 1.3;

}

figure.snip1157 blockquote::before {

  top: 25px;
  left: 10px;
  font-size: 35px !important;
  font-weight: 100 !important;
  font-family: initial;
  font-size: 35px;

}
figure.snip1157 blockquote:before {
  top: 25px;
  left: 10px;
 
}
figure.snip1157 blockquote:after {
  content: "\201D";
  right: 20px;
  bottom: 10px;
  font-family: initial;
  font-size: 35px !important;

}
figure.snip1157 .arrow {
  top: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #008bd0;
  margin: 0;
display: none;
}
figure.snip1157 .author {
  position: relative;
  bottom: 45px;
  left: 55px;
  margin: 0;
  text-transform: uppercase;
  color: black;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  padding-top: 30px;
  padding-bottom: 30px;
}
figure.snip1157 .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 600;
  font-size: 15px;
}
figure.snip1157 .author h5 span {
  font-weight: 400;
  text-transform: none;
  padding-left: 5px;
}


/* Demo purposes only */
