li.login-btn {
  display: none;
}
section#chat {
  padding: 0px;
}

section#contact textarea.form-control {
  min-height: calc(5.5em + 0.75rem + 2px);
}

section#services {
  padding: 0px !important;
}
section#query {
  padding: 0px !important;
}
.helping-box {
  min-height: 172px;
}

.search-result .icon-box {padding: 10px;flex-direction: column;justify-content: center; align-items:center;text-align: center; background-color: #466272; text-align: center;}

.search-result h3{
  text-align: center;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

.btn{
  color: white;
}

.btn1:hover{
  color: white;
  background-color: #008bd9;
}

.btn:hover{
  color: white;
  background-color: #0d80fa;
}

.new-button:hover {
  background-color: #008bd0;
  color: #fff;
}

body {

  font-family: 'Inter', sans-serif;
  color: #000;
  
}

a {
  color: #008bd0;
  text-decoration: none;
}

a:hover {
  color: #008bd0;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {

  font-family: 'Inter', sans-serif;
 
}
p, ol li{color:#424242;}
p, small, ul li, a, address, ol li{ font-family: 'Inter', sans-serif;}
.text-black{color:#000;}

.c1{
color: #008bd0;
}

.privacy h5 {
  color: #008bd0;
}


/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {padding: 20px 0 10px 0;overflow: hidden;}
.section-bg {background-color: #f5f5ff;}
.section-title {padding-bottom: 40px;}
.section-title h2 {font-size: 14px;font-weight: 500;padding: 0;line-height: 1px;margin: 0 0 5px 0;letter-spacing: 2px;text-transform: uppercase;color: #aaaaaa;}
.section-title h2::after {content: "";width: 120px;height: 1px;display: inline-block;background: #008bd0;margin: 4px 10px;}
.section-title p {margin: 0;margin: 0;font-size: 36px;font-weight: 700;text-transform: uppercase;color: #008bd0;}

/***************Inner Page Css***************************/

/* Mobile */
.inner-frame{background-color: #e5f1f8;}
.bg-dlight{background-color: #e5f1f8;}
.icon-box ul li{font-size:14px; display:flex; align-items: center; color:#424242; line-height: 35px;}
.icon-box ul li i{color:#008bd0; font-size:18px;padding-right:8px;}

/*--------------------------------------------------------------
# Privacy css
--------------------------------------------------------------*/
.privacy ol ul li{margin-bottom:10px;list-style-type: disc;}
.s-img{width:90%;}

@media (max-width:360px){
  .services .icon-box img, .insta-post .member img{min-width:326px!important;}
}

@media (max-width: 767px) {
    #header .logo img {max-height: 30px;margin:0;}
  .login-btn {top:0px;z-index:1;left:0;background: rgba(229, 241, 248, 1);}
  .navbar-mobile a, .navbar-mobile a:focus {padding: 10px 10px;}
  .navbar-mobile .login-btn{right:16px;}
  .navbar-mobile>ul>li>a:before{left:10px; top:33px;}
  .search-box .input-group{flex-direction: column;text-align: center;}
  .search-inner .form-control{width: 100%;margin-bottom:10px;}
  .search-inner .selectpicker{width: 100%;margin-bottom:10px;}
  .services .icon-box img, .insta-post .member img{min-width:355px;}
  .testimonials .swiper-button-next, .testimonials .swiper-button-prev{width:25px; height:25px;top:60%; left:0}
  .testimonials .swiper-button-next{right:0;}
  .testimonials .swiper-button-prev{left:0;}
  .top-head{margin-top:25px;}
  .contact {border: 20px solid #e5f1f8;}
  .insta-post .member {margin-bottom: 20px;}
  #footer .footer-top .footer-info {margin: -20px 0 30px 0;}
  .demo-btn{width: 100%;}
  .style-agile-border{display: none;}
  .testi-author{flex-direction: column; text-align: center;}
  .testi-author img{margin:0 0 20px;}
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .login-btn {top:63px;z-index:-1;}
  .navbar-mobile .mobile-nav-toggle {position: absolute; top: 11px; right: 35px;}
  .navbar-mobile .login-btn{right:20px;}
  .navbar-mobile ul{top:62px;}
  .hero-img img {position: relative;left: 0px;top: 25px;}

  .testimonials .swiper-button-next, .testimonials .swiper-button-prev{width:35px; height:35px;top:60%;}
  .demo-btn{width: 50%;}
  .s-img{width:70%;}
  .testi-author{flex-direction: column; text-align: center;}
  .testi-author img{margin:0 0 20px;}
}

@media only screen and (min-width:992px) and (max-width:1199px){
  .services .icon-box img{width:290px;}
  .demo-btn{width: 70%;}
}

@media (min-width:1400px){
  .about {padding: 80px 0 0 0;}
  .why-us{padding:60px 0;}
  .featured .member img{width:100%;}
  .our-mission .pic img {width: 15%;}
}

/*********Live Chat Button******************/
button#button-body {
  background: none !important;
  background-color: #008bd0 !important;
  box-shadow: rgb(0 139 208) 0px 2px 16px !important;
}