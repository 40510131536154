.single{
    background-color: #eef1f3;
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
}

.selectWrapper{
    border-radius:36px;
    display:inline-block;
    overflow:hidden;
    background:#fff;
    border:1px solid #cccccc;
    margin: 10px 30px;
    
  }
  .selectBox{
    background-color: white;
    width:200px;
    height:40px;
    border:0px;
    outline:none;
    padding-left: 15px;
    margin-right: 15px !important;
  }

  .usercard{
    border-radius: 20px;
    text-align: left;
    position: relative;
    box-shadow: 0px 1px 19px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    margin-bottom: 5px;
   

  }

  .error_span{
    color: red;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
  }

  .userimage{
    position: absolute;
    top:-15% ;
    left: 10%;

    border-radius: 50%;
    background-color: rgb(197, 197, 209);
  }

  .usercardbody span{
font-size: 12px;
  }

  .usercardbutton{
    background-color: #008bd0;
    border-radius: 6px;
    border: none;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fff;
    font-size: 13px;
  }

  .usercardbody h4{
    font-size: 18px;
      }

      .usercardbody p{
        font-size: 14px;
        color: #424242;
          }
          
          .circle {
            display: inline-block;
              background-color: #FFE1E2;
              margin: 5px;
              border-radius: 50%;
             
          }
          

          .circle2{
            display: inline-block;
              background-color: #E1F3FC;
              margin: 5px;
              border-radius: 50%;
             
          }

          .circle-inner {
            color: #E93C3E;
            display: table-cell;
            vertical-align: middle; 
            text-align: center;
            text-decoration: none;
            height: 80px;
            width: 80px;  
            font-size: 30px;
          }

          .circle-inner2 {
            color: #3489CE;
           
            display: table-cell;
            vertical-align: middle; 
            text-align: center;
            text-decoration: none;
            height: 80px;
            width: 80px;  
            font-size: 30px;
          }
.rating{
    background-color: #f7f7f7;
    border-radius: 25px;
    padding: 3px 3px;
}

.usercardname{
    font-weight: 700;
}

.cardnewwidth{
  width: 33%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .cardnewwidth{
    width: 50%;
  }
}

@media only screen and (max-width: 567px) {
  .cardnewwidth{
    width: 100%;
  }
}


.blur_item p{
    
  text-shadow: #008bd0 0 0 15px;
  transition: 0.4s;
  font-size: 14px !important;
  color: #008bd0 ;
  -webkit-filter: blur(5px);
}

.non_blur_item{
  text-align: left;
  transition: 0.4s;
  font-size: 14px !important;
  color: #008bd0 ;
  
}

.non_blur_item a{
 display: none;
  
}

.new_input_div{
display: flex;
justify-content: center;
align-items: center;
margin-top: 20px;
margin-bottom: 20px;

}

.new_input_div input{
  padding: 10px;
border: 0.5px;
width: 60%;
outline: none;
}
.new_input_div button{
  padding: 10px;
  outline: none;
  border: 0.5px;
  background-color: #008bd0;
  color: white;
}