.containerNav{
 padding: 0;
}

.navbar-toggler collapsed{
  margin-top: 10px;
}

.navbar-nav {

  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  
 
}
@media only screen and (max-width:567px){
  

.new-nav .a1{
  position: absolute;
  top: 60px;
  right: 0;
  width: fit-content;
  height: fit-content;
  }

  .new-nav .a2{
    position: absolute;
    top: 10px;
    right: 0px;
    width: fit-content;
    height: fit-content;
    }

}

.show{
  transition: all 0.4s smooth;

}

  .navbarp {
    padding: 7px;
    color: white;
    background-color: #008bd0;
    box-shadow: 0px 0px 1px 0px #008bd0;
    margin: 5px;
}

    .nav_span{
        color: white;
        font-weight: 600;
  
    }


    .header {
      width: 100%;
      border: none;
      z-index: 997;
      top: 0;
      position: fixed;
      transition: all 0.5s;
      height: 60px;
      background: transparent !important;
    }
    
    .navspan{
      color: white;
    }
    
    .scrolled .navspan{
      color: black;
    }
    
    .header.scrolled{
      height: 60px;
      background-color: #ffffff!important;
      /* border-bottom:1px solid #424242 ; */
      box-shadow: 0 .1rem .5rem rgba(0,0,0,.15);
     
    width: 100%;
     
    }
    #header.header-scrolled .navbar a{color:#000;}
    #header.header-scrolled .navbar a:hover{color:#e73c3f;}
    
    #header .logo h1 {
      font-size: 28px;
      margin: 0;
      padding: 0;
      line-height: 1;
      font-weight: 300;
    }
    
    #header .logo h1 a,
    #header .logo h1 a:hover {
      color: #fff;
      text-decoration: none;
    }
    
    #header .logo img {
      padding: 0;
      margin: 0px 0 0;
      max-height: 40px;
    }
    #header.header-scrolled .logo img{margin:0;}
    #header.header-scrolled .login-btn{background:rgba(229, 241, 248, 1);}
    /*--------------------------------------------------------------
    # Navigation Menu
    --------------------------------------------------------------*/
    /**
    * Desktop Navigation 
    */
   
 .collapse .show{
      background-color: #f5f5f5;
    }

.navbar {
    padding: 5px 0 0 0;
}
    .navbar ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
   
      width: 100%;
      margin-top: 10px;
  }

    .navbar-collapse {
      flex-basis: 100%;
      flex-grow: 0;
      align-items: center;
  }
    
    .navbar li {
      position: relative;
    }
    
    .navbar a, .navbar a:focus {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 14px;
      font-weight: 600;
      font-family: 'Inter', sans-serif;
      color: rgba(0, 0, 0, 1);
      white-space: nowrap;
      transition: 0.3s;

      color: white;
  }

    .navbar a i,
    .navbar a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
    }
    
    .navbar>ul>li>a:before {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 3px;
      left: 30px;
      background-color: #e73c3f;
      visibility: hidden;
      width: 0px;
      transition: all 0.3s ease-in-out 0s;
    }
    
    .navbar a:hover:before,
    .navbar li:hover>a:before,
    .navbar .active:before {
      visibility: visible;
      width: 25px;
    }
    
    .navbar a:hover,
    .navbar .active,
    .navbar .active:focus,
    .navbar li:hover>a {
      color: #f5f5f5;
    }
    
    .navbar .dropdown ul {
      display: block;
      position: absolute;
      left: 30px;
      top: calc(100% + 30px);
      margin: 0;
      padding: 10px 0;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      transition: 0.3s;
      border-radius: 4px;
    }
    
    .navbar .dropdown ul li {
      min-width: 200px;
    }
    
    .navbar .dropdown ul a {
     
      font-size: 14px;
      font-weight: 500;
      text-transform: none;
      color: #424242;
    }
    
    .navbar .dropdown ul a i {
      font-size: 12px;
    }
    
    .navbar .dropdown ul a:hover,
    .navbar .dropdown ul .active:hover,
    .navbar .dropdown ul li:hover>a {
      color: #e73c3f;
    }
    
    .navbar .dropdown:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
    
    .navbar .dropdown .dropdown ul {
      top: 0;
      left: calc(100% - 30px);
      visibility: hidden;
    }
    
    .navbar .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: 100%;
      visibility: visible;
    }
    
    .login-btn{position:absolute; right:0; top:0px; left:20px; background-color: #fff; text-align: center; display: flex; align-items: center;}
    .login-btn a{ color:#008bd0!important; padding:10px!important;}
    .login-btn a:hover{color:#008bd0!important;}
    .navbar > ul > li.login-btn > a::before{background-color: transparent;}
    
    
    @media (max-width: 1366px) {
      .navbar .dropdown .dropdown ul {
        left: -90%;
      }
    
      .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
      }
    }
    
    
    .mobile-nav-toggle {
      color: #fff;
      font-size:32px;
      cursor: pointer;
      display: none;
      line-height: 0;
      transition: 0.5s;
      background: #008bd0;
      border-radius: 5px;
      padding: 3px 5px;
    }
    
    @media only screen and (max-width: 991px)  {
      .mobile-nav-toggle {
        display: block;
      }
    
    
    }
    
    .navbar-mobile {
      position: fixed;
      overflow: hidden;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(229, 241, 248, 1);
      transition: 0.3s;
      z-index: 999;
    }
    
    .navbar-mobile .mobile-nav-toggle {
      position: absolute;
      top: 11px;
      right: 11.8px;
    }
    
    .navbar-mobile ul {
      display: block;
      position: absolute;
      top: 55px;
      right: 15px;
     
      left: 15px;
      padding: 10px 0;
      border-radius: 8px;
      background-color: #302828;
      overflow-y: auto;
      transition: 0.3s;
    }
    
    .navbar-mobile>ul>li>a:before {
      left: 20px;
    }
    
    .navbar-mobile a,
    .navbar-mobile a:focus {
      padding: 10px 20px;
      font-size: 15px;
      color: #000;
    }
    
    .navbar-mobile a:hover,
    .navbar-mobile .active,
    .navbar-mobile li:hover>a {
      color: #e73c3f;
    }
    
    .navbar-mobile .getstarted,
    .navbar-mobile .getstarted:focus {
      margin: 15px;
    }
    
    .navbar-mobile .dropdown ul,
    .navbar-mobile .dropdown .dropdown ul {
      position: static;
      display: none;
      margin: 10px 20px;
      padding: 10px 0;
      z-index: 99;
      opacity: 1;
      visibility: visible;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    }
    
    .navbar-mobile .dropdown ul li,
    .navbar-mobile .dropdown .dropdown ul li {
      min-width: 200px;
    }
    
    .navbar-mobile .dropdown ul a,
    .navbar-mobile .dropdown .dropdown ul a {
      padding: 10px 20px;
    }
    
    .navbar-mobile .dropdown ul a i,
    .navbar-mobile .dropdown .dropdown ul a i {
      font-size: 12px;
    }
    
    .navbar-mobile .dropdown ul a:hover,
    .navbar-mobile .dropdown ul .active:hover,
    .navbar-mobile .dropdown ul li:hover>a,
    .navbar-mobile .dropdown .dropdown ul a:hover,
    .navbar-mobile .dropdown .dropdown ul .active:hover,
    .navbar-mobile .dropdown .dropdown ul li:hover>a {
      color: #e73c3f;
    }
    
    .navbar-mobile .dropdown>.dropdown-active,
    .navbar-mobile .dropdown .dropdown>.dropdown-active {
      display: block;
    }

  
    @media only screen and (max-width:767px){
      .navbar a, .navbar a:focus {
        padding: 4px 5px;
        margin: 10px 0px 10px 7px;
        font-weight: 500;
      } 



      .navbar-logo{width: 75%; float: left;}
      .navbar-toggler{width: 50px; padding: 2px; float: right;}
      .navbar-toggler:focus{box-shadow: none; outline:none;}
      .navbar-collapse .navbar-nav{justify-content: end;}

      
    .nav-img {
      width: 64%;
    }
  
    }