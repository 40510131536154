section#about h2 {
    font-size: 30px;
    font-weight: 600 !important;
  }
  section#about img {
    width: 80%;
  }
  .member {
    cursor: pointer;
}

  .about .icon img {
    width: 70%;
  }
  .about {padding: 40px 0 0 0;}
  .about .icon-boxes h3 {font-size: 28px;font-weight: 600;color: #010483;margin-bottom: 15px;}
  .about .icon-box {margin-top: 20px;}
  .about .icon-box .icon {float: left;display: flex;align-items: center;justify-content: center;width: 50px;height: 50px;border: 2px solid #008bd0;background: #008bd0;border-radius: 50px;transition: 0.5s;}
  .about .icon-box .icon i {color: #fff;font-size: 32px;}
  .about .icon-box:hover .icon i {color: #fff;}
  .about .icon-box .title {font-weight: 600;margin-bottom: 10px;font-size: 18px; height: 40px; text-align: center;}
  .about .icon-box .title a {color: #343a40;transition: 0.3s;}
  .about .icon-box .title a:hover {color: #1acc8d;}
  .about .icon-box .description {line-height: 20px;font-size: 14px;text-align: center;color: #424242;}
  .about .video-box {background-size: contain;}

.specialization:disabled{
  background-color: white;
}

  .icon-box.aos-init.aos-animate {
    padding-bottom: 5px;
    padding-top: 10px;
    box-shadow: 0px 0px 3px #999797;
    background-color: #eaf0f4;
  }
  
  .services .services-carousel, .services .services-slider {overflow: hidden;}
  .services .swiper-slide{padding: 10px 0; text-align: center; height: 300 px; padding: 10px; } 
  .services .services-item {text-align: center;color: #fff;}
  .services .services-item .services-img {width: 100px;border-radius: 50%;border: 6px solid rgba(255, 255, 255, 0.15);margin: 0 auto;}
  .services .services-item h3 {font-size: 16px;font-weight: bold;margin: 10px 0 5px 0;color: #fff;}
  .services .services-item h4 {font-size: 14px;color: #ddd;margin: 0 0 15px 0;}
  .services .services-item .quote-icon-left, .services .services-item .quote-icon-right {color: rgba(255, 255, 255, 0.4);font-size: 26px;}
  .services .services-item .quote-icon-left {display: inline-block;left: -5px;position: relative;}
  .services .services-item .quote-icon-right {display: inline-block;right: -5px;position: relative;top: 10px;}
  .services .services-item p {font-style: italic;margin: 0 auto 15px auto;color: #eee;}
  .services .swiper-pagination {margin-top: 20px;position: relative;bottom:40px;}
  .services .swiper-pagination .swiper-pagination-bullet {width: 18px;height: 18px;padding:3px;opacity: 1;background-color: transparent;border:1px solid #000;}
  .services .swiper-pagination .swiper-pagination-bullet-active {background-color: #a02051;padding:3px;}
  .services .icon-box {display: flex;flex-direction: column;padding:5px 15px;background: #fff;transition: ease-in-out 0.3s;border-radius:0;color:#000;box-shadow: 0px 0px 1px 2px rgba(74, 74, 74, 0.05);}
  .services .icon-box img {padding: 0px 0 0 0;transition: ease-in-out 0.3s;}
  .services .icon-box h3 {font-weight: 600;margin: 0;padding: 10px 0;line-height: 1;font-size: 16px;color: #000;transition: ease-in-out 0.3s;}
  .services .icon-box p {color: #000;transition: ease-in-out 0.3s;font-style: normal;font-size: 14px; font-weight: 600;}
  .services .icon-box a {color: #000;transition: ease-in-out 0.3s;font-size: 15px; font-weight:500;}
  .services .icon-box:hover {background: #e5f1f8;}
  .services .icon-box small {font-size: 13px;font-weight: 600;color: #424242;padding-top: 10px;}
  .swiper-initialize{overflow: hidden;}
  .img-frame{position: relative;}
  .heart-bg{position: absolute; bottom:5px; right:8px; background-color:#e73c3f; padding:2px 8px;}
  .heart-bg i{color:#fff;}
  .rating-box {text-align: center; margin-bottom: 20px;}

 .rating-box .stars {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}
  .stars i {font-size: 15px;color: #73cf42;transition: all 0.2s;cursor: pointer;}
  .stars i.active {color: #3f9014;}
  .services .btn{border-radius:0; border:1px solid #c5c5c5; font-size:14px;}
  .services .btn i{font-size:20px; margin-right:5px;}
  .services .btn:hover{background-color: #008bd0; border-color:#008bd0; color:#fff;}
  .services .swiper-button-next{right:0px;}
  .services .swiper-button-prev{left:0;}
  .services .swiper-button-next, .services .swiper-button-prev{background-color:#008bd0; color:#fff;  border-radius:50%;width:32px;height:32px; top:50%;right:-0%; opacity: 0.9; transition: all linear 0.3s; z-index: 333;}
  .services .swiper-button-next:hover, .services .swiper-button-prev:hover, .services .swiper-button-next:focus, .services .swiper-button-prev:focus{opacity:1;background-color:#e33b3f ; border-color:#008bd0; color:#fff;}
  .services .swiper-button-next::after, .services .swiper-button-prev::after{font-size:14px; font-weight:600;}
  
  
/*--------------------------------------------------------------
# featured
--------------------------------------------------------------*/
.featured {
    background: #fff;
  }
  .top-head{background-color: #008bd0; width: 50%; color:#fff; text-align: center; padding: 5px 0; font-size:16px;}
  .featured .member {
    margin-bottom: 0px;
    position: relative;
    box-shadow: 0px 0px 1px 2px rgba(74, 74, 74, 0.05);
    transition: ease-in-out 0.3s;
  }
  .featured .member:hover{background: #e5f1f8;}
  .featured .member .pic {
    border-radius: 0px;
    overflow: hidden;
    text-align: center;
    padding: 5px;
  }
  .featured .member img {
    transition: all ease-in-out 0.4s;
  }
  .featured .member:hover img {
    transform: scale(1.1);
  }
  .featured .member h4 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 18px;
    color: #000;
    position: relative;
  }
  .featured .member-info{padding:10px;}
  .featured .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #008bd0;
  }
  .featured .member .social {
    margin-top: 10px;
  }
  .featured .member a {
    transition: color 0.3s;
    color: #424242;
    font-size:14px;
    font-weight: 600;
  }
  .featured .member a:hover {
    color: #008bd0;
  }
  .featured .member .social i {
    font-size: 16px;
    margin: 0 2px;
  }

  /*--------------------------------------------------------------
# Search Css Start
--------------------------------------------------------------*/

.search-box{position:relative; top:15px; padding-top:5px; right: 30px;}
/* .search-inner{ box-shadow:0px 0px 2px 2px rgba(67, 67, 67, 0.3)} */
.selectpicker{background-color: #fff; color:#424242;  border-radius:0; border:1px solid #ced4da; font-size:14px; font-weight:500; width:130px; height: 44px;}
.search-inner .btn{border-radius:0;font-size:14px; font-weight: 600;box-shadow:none; line-height: 30px; background-color: #008bd0;}
.search-inner .btn:hover{background-color: #0180bf;}

.search-inner .btn[disabled] {
  /* Override default styles */
  opacity: 1; /* or other appropriate styles */
  color: white;
  border-radius:0;font-size:14px; font-weight: 600;box-shadow:none; line-height: 30px; background-color: #008bd0;
  border: 1px solid #008bd0;
}



.search-inner .form-control1{box-shadow:none; border-color:#ced4da; color:#424242;font-size:14px; font-weight:500; border-radius: 0; height: 44px; width: 200px;  outline: none; border: 0.5px solid #ced4da; padding-left: 10px;}
.search-inner .form-control{box-shadow:none; border-color:#ced4da; color:#424242;font-size:14px; font-weight:500; border-radius: 0; height: 44px; width: 300px; outline: none; border-left: 0.5px solid #424242;}


.fs-14{font-size:14px;}
.search-result{margin-top:65px; background-color: #e5f1f8; padding-top: 30px !important; padding-bottom: 30px  !important; }
.search-result p i{font-size:18px;}
.search-result .icon-box {
  font-weight: 600;
    align-items: flex-start;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 0px 1px 2px rgba(74, 74, 74, 0.05);
    transition: ease-in-out 0.3s;
    cursor: pointer;
    text-align: left;
}
.search-result .icon-box img {padding-right: 10px;}
.search-result .icon-box h3 {font-weight: 700;margin: 0 0 5px;padding: 0;line-height: 1;font-size: 16px;color: #e73c3f;transition: ease-in-out 0.3s;   padding-top: 5px;}
.search-result .icon-box p{ line-height: 16px;   padding-top: 5px;}
.search-result .icon-box p small{font-size:12px; font-weight: 400; color:#424242;   padding-top: 5px;}
.search-result .icon-box address{font-size:12px; line-height: 20px; font-weight: 600; color:#000;   padding-top: 5px;}
.search-result .icon-box address i{font-size:16px;}

.search-result .icon-box:hover {background: #e5f1f8;}
.search-result .icon-box .btn {width: 200px; color: white; background-color: #008bd0; font-weight: 500;}
@media only screen and (min-width:767px) and (max-width:991px){
  .search-result .icon-box .btn {width: 150px;}
  .search-result .icon-box {height: 300px;}
  .search-inner .input-group{
    justify-content: center;
  }
}

@media only screen and (max-width:767px){
  .search-result .icon-box {margin-top: 20px;}
}
.icon-box h5.title {
  padding-top: 5px;
}
.word-new-text{
  text-align: center !important;
}

/* 
--------------------------------------video------------------------------------- */

section#video-box {
  padding-top: 0px;
}
section#video-box h2.fw-semibold.mb-3 {
  font-size: 30px;
  line-height: 32px;
}
.video-content {
  margin-top: 40px;
}
section#video-box {
  background-color: #fff;
}
.video-inner {
  padding: 80px;
  background-color: #e5f1f8;
  margin: 70px 40px;
  margin-bottom: 10px;
  box-shadow: 4px 4px 8px #dad8d8;
    border-radius: 5px;
}

@media (max-width: 767px) {
  .video-inner {
    padding: 0px;
    background-color: #fff;
    margin: 40px 0px;
    margin-bottom: 10px;
}}

.relative__Div{
  box-sizing: border-box;
  border: 1px solid #424242;
}

.relative__divrow{
position: relative;
background-color: #fff;
color: #000;
z-index: 2;
border-right: 1px solid #424242;
border-left: 1px solid #424242;

  max-height: 200PX;
  overflow-y: scroll;

}



@media only screen and (max-width:767px){
    .search-inner .form-control{ width: 100% !important; border-left: 0; }
    .search-inner .form-control1{width: 100% !important; margin-bottom: 10px; }
  }

.loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .name-red{
    color:#e73c3f !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    height: 42px;
  }

  .small1{
    height: 25px;
    text-align: left;
  }

  .small2 {
    height: 50px;
    text-align: left;
}