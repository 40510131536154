
.index {
  background-image: url("../../assets/img/about\ B1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 400px;
  width: 90%;
  margin-left: 5%;
  border-radius: 50px;
  margin-top: 20px;
  box-sizing: border-box;
}

.about__banner{
  position: relative;
 
}

.about__banner h1{
  position: absolute;
  top: 20%;
  left: 60%;
}

.about__banner p{
  position: absolute;
  top: 40%;
  left: 40%;
  text-align: center;
}


.vision {
  width: 100%;
  background-color: #e5f1f8;
}

.vision .card {
  height: 250px;
}

.vision h4 {
  color: black;
}

.bg {
  background-color: #e5f1f8;
}

.paratext {
  color: white;
}

.icon__about {
  width: 50px;
  background-color: #008ad0;
  border-radius: 50%;
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.icon__about2 {
  width: 50px;
  background-color: #eb3e41;
  border-radius: 50%;
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.socialIcon {
  width: 20px;
  margin: 10px;
}

.socialIcon:hover {
  transform: scale(1.18);
  transition: 0.6s;
}

.inner__col {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}



.desc__box {
  box-shadow: 3px 3px 3px 3px rgba(17, 7, 7, 0.05);
  padding: 50px;
  border-radius: 30px;
}



.responsive-cell-block {
  min-height: 75px;
}

.text-blk {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  line-height: 25px;
}

.responsive-container-block {
  min-height: 75px;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  justify-content: space-evenly;
}

.team-head-text {
  font-size: 48px;
  font-weight: 900;
  text-align: center;
}

.team-head-text {
  line-height: 50px;
  width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 50px;
  margin-left: 0px;
}

.card {
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 20px 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-right: 25px;
  padding-bottom: 30px;
  padding-left: 25px;
}

.card-container {
  width: 240px;
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: 25px;
  margin-left: 10px;
}

.name {
  margin-top: 20px;
  margin-right: 0px;
  margin-bottom: 5px;
  margin-left: 0px;
  font-size: 18px;
  font-weight: 800;
}

.position {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
}

.feature-text {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  margin-left: 0px;
  color: rgb(122, 122, 122);
  line-height: 30px;
}

.social-icons {
  width: 70px;
  display: flex;
  justify-content: space-between;
}

.team-image-wrapper {
  clip-path: circle(50% at 50% 50%);
  width: 130px;
  height: 130px;
}

.team-member-image {
  max-width: 100%;
}

.teamcard {
  background-color: #e5f1f8;
}

.teamcard:hover {
  transform: scale(1.08);
  transition: 0.6s;
}

@media (max-width: 500px) {
  .card-container {
    width: 100%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 25px;
    margin-left: 0px;
  }

  .inner__col {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .ourmission {
    text-align: center;
  }
}

@media only screen and (max-width:768px) {
  .index{margin-top: 60px!important;}
  .inner__col {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .ourmission {
    text-align: center;
  }

}

@media only screen and (max-width:991px) {

  .index {
    background-image: url("../../assets/img/about\ B1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 330px;
    width: 90%;
    margin-left: 5%;
    border-radius: 50px;
    margin-top: 20px;
  }

  .vision {
    background-image: url("../../assets/img/abouttt.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
  }

  .vision p {
    color: black;
  }

}
@media only screen and (max-width:768px) {
  .about__banner p {
    position: absolute;
    top: 30%;
    left: 0%;
    width: 100% !important;
}
.index {
  background-color: #a6bcc9 !important;
  background-image: none !important;
  width: 100% !important;
  margin: 0;
  border-radius: 0px !important;
  padding: 0 20px;
}
.about__banner h1{
  left: 30%;
  top: 15%;
}


}
@media only screen and (max-width:768px) {
  .vision .card {
    height: 280px;
}
}