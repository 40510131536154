.singlebloghero{
    background: url("../../assets/img/Accidentmate//blog.jpg");
    background-repeat: no-repeat;
    background-position: center center;
     background-size: cover;
     margin-bottom: 20px;
}

.singlebloghero h1{
    font-weight: 500;
    color: white;
}

.singleblog h1{
    font-weight: 500;
  
    text-align: center;
    padding: 10px 20px;
}

.singleblog p{
    font-weight: 500;
    font-size: 20px;
    padding: 10px 20px;

}