#footer{
   background-image: url("../../assets/img/footer-bg.png");
   background-repeat: no-repeat;
   background-position: center center;
   background-size: cover;
  
   padding: 0 0 30px 0;
   color: #fff;
   font-size: 14px;
}



  #footer p{color:#fff;}
 .footer-top {
    padding: 60px 0 30px 0;
  }
  
 .footer-info {
    margin-bottom: 15px;
    color: #fff;
  }
  .t-right{text-align: right;}
.footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  
  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Montserrat", sans-serif;
  }
  
  #footer .footer-top .social-links a {
    display: inline-block;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    opacity: 0.8;
  }
  
  #footer .footer-top .social-links a:hover {
    opacity: 1;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 10px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-links ul i {
    padding-right: 2px;
    color: #008bd0;
    font-size: 18px;
    line-height: 1;
  }
  
.footer-links ul li {
    padding: 10px 0 3px;
    display: flex;
    align-items: center;
  }
  
  .footer-links ul li:first-child {
    padding-top: 0;
  }
  
.footer-links ul a{
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
 .footer-links ul a:hover {
    color: red;
  }
  
  .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px 6px 15px;
    position: relative;
    border-radius: 50px;
  }
  
   .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
  }
  
.footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #1acc8d;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
  }
  
  #footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #149f6e;
  }
  
  #footer .copyright {
    border-top: 1px solid #fff;
    text-align: center;
    padding-top: 30px;
  }
  
  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }
  
  #footer .a-info{position: relative; margin-left: 0px; line-height: 26px;}
  #footer .a-info i{position: absolute; left: -20px;}