.modal_center h3{
   color: black;
}
.modal_center p{
    color: #fff;
}

.modal_center{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
   
}
.ReactModal__Content.ReactModal__Content--after-open {
  padding: 0 !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  width: 33% !important;
  inset: 15% 0px 0px 33% !important;
  background-color: #fff !important;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  border: 1px solid #dee2e6 !important;
  }
  .modal_center h3.text-center.fw-bold {
    font-size: 20px;
    font-weight: 600 !important;
}
.ReactModal__Content.ReactModal__Content--after-open {
    background-color: #fff !important;
    border: none !important;
  }
  .modal_center .btn.btn-primary.text-center {
    background-color: #008bd0 !important;
    padding: 7px 24px;
  }

  .modall_img{
    width: 200px;

  }
  .modal_center .oops {
    color: #e84346;
}
  @media only screen and (max-width: 767px) {
    .ReactModal__Overlay.ReactModal__Overlay--after-open {
      width: 76% !important;
      inset: 12% 0px 0px 12% !important;
    }

  }
  .modal-header.text-center {
    display: block;
    background-color: #e5f1f8 !important;
}
.modal-header {
  background-color: #e5f1f8 !important;
}
.modal-header button.btn-close {
    display: flex;
    position: relative;
    top: 0px;
}
.modal-footer {
    background-color: #e5f1f8;
}