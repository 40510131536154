  
  .services .services-carousel, .services .services-slider {overflow: hidden;}
  .services .swiper-slide{padding: 10px 0; text-align: center; height: max-content; padding: 10px; } 
  .services .services-item {text-align: center;color: #fff;}
  .services .services-item .services-img {width: 100px;border-radius: 50%;border: 6px solid rgba(255, 255, 255, 0.15);margin: 0 auto;}
  .services .services-item h3 {font-size: 16px;font-weight: bold;margin: 10px 0 5px 0;color: #fff;}
  .services .services-item h4 {font-size: 14px;color: #ddd;margin: 0 0 15px 0;}
  .services .services-item .quote-icon-left, .services .services-item .quote-icon-right {color: rgba(255, 255, 255, 0.4);font-size: 26px;}
  .services .services-item .quote-icon-left {display: inline-block;left: -5px;position: relative;}
  .services .services-item .quote-icon-right {display: inline-block;right: -5px;position: relative;top: 10px;}
  .services .services-item p {font-style: italic;margin: 0 auto 15px auto;color: #eee;}
  .services .swiper-pagination {margin-top: 20px;position: relative;bottom:40px;}
  .services .swiper-pagination .swiper-pagination-bullet {width: 18px;height: 18px;padding:3px;opacity: 1;background-color: transparent;border:1px solid #000;}
  .services .swiper-pagination .swiper-pagination-bullet-active {background-color: #a02051;padding:3px;}
  .services .icon-box {display: flex;flex-direction: column;padding:5px;background: #fff;transition: ease-in-out 0.3s;border-radius:0;color:#000;box-shadow: 0px 0px 1px 2px rgba(74, 74, 74, 0.05);}
  .services .icon-box img {padding: 0px 0 0 0;transition: ease-in-out 0.3s;}
  .services .icon-box h3 {font-weight: 600;margin: 0;padding: 10px 0;line-height: 1;font-size: 16px;color: #000;transition: ease-in-out 0.3s;}
  .services .icon-box p {color: #000;transition: ease-in-out 0.3s;font-style: normal;font-size: 13px; font-weight: 600;}
  .services .icon-box a {color: #000;transition: ease-in-out 0.3s;font-size: 15px; font-weight:500;}
  .services .icon-box:hover {background: #e5f1f8;}
  .services .icon-box small {font-size: 12px;font-weight: 400;color: #424242;padding-top: 10px;}
  .swiper-initialize{overflow: hidden;}
  .img-frame{position: relative;}
  .heart-bg{position: absolute; bottom:5px; right:8px; background-color:#e73c3f; padding:2px 8px;}
  .heart-bg i{color:#fff;}
  .rating-box-business {text-align: center; margin-bottom: 20px;}
  .rating-box-business .stars {display: flex;align-items: center;justify-content: center;gap: 5px;}
  .stars i {font-size: 15px;color: #73cf42;transition: all 0.2s;cursor: pointer;}
  .stars i.active {color: #3f9014;}
  .services .btn{border-radius:0; border:1px solid #c5c5c5; font-size:14px;}
  .services .btn i{font-size:20px; margin-right:5px;}
  .services .btn:hover{background-color: #008bd0; border-color:#008bd0; color:#fff;}
  .services .swiper-button-next{right:0px;}
  .services .swiper-button-prev{left:0;}
  .services .swiper-button-next, .services .swiper-button-prev{background-color:#008bd0; color:#fff;  border-radius:50%;width:32px;height:32px; top:50%;right:-0%; opacity: 0.9; transition: all linear 0.3s; z-index: 333;}
  .services .swiper-button-next:hover, .services .swiper-button-prev:hover, .services .swiper-button-next:focus, .services .swiper-button-prev:focus{opacity:1;background-color:#e33b3f ; border-color:#008bd0; color:#fff;}
  .services .swiper-button-next::after, .services .swiper-button-prev::after{font-size:14px; font-weight:600;}
  .business{
    background-color: #eef1f3;
    font-weight: 600;
  }
