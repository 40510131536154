.newshero{
    background: url("../../assets/img/Accidentmate/blog.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    text-align: center;
    color: white;
    
}

.member-info h4{
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    clear: both;
}

.blog .entry {padding: 30px;margin-bottom: 60px;box-shadow:0px 0px 1px 2px rgba(74, 74, 74, 0.05);}
.blog .entry .entry-img {max-height: 440px;margin: -30px -30px 20px -30px;overflow: hidden;}
.blog .entry .entry-content h5{font-weight:600; color: #008bd0; font-size: 20px;}
.blog .entry .entry-content ul li{color:#424242; line-height: 1.6;}
.blog .entry .entry-content p {line-height: 24px;}
.blog .sidebar {padding: 30px;margin: 0 0 60px 0px;box-shadow:0px 0px 1px 2px rgba(74, 74, 74, 0.05);}
.blog .sidebar .sidebar-title {font-size: 20px;font-weight: 700;padding: 0 0 0 0;margin: 0 0 15px 0;color: #008bd0;position: relative;}
.blog .sidebar .sidebar-item {margin-bottom: 30px;}
.blog .sidebar .recent-posts .post-item+.post-item {margin-top: 15px;}
.blog .sidebar .recent-posts img {width: 80px;float: left;}
.blog .sidebar .recent-posts h4 {font-size: 15px;margin-left: 95px;font-weight: 600;}
.blog .sidebar .recent-posts h4 a {color: #000;transition: 0.3s;}
.blog .sidebar .recent-posts h4 a:hover {color: #008bd0;}
.blog .sidebar .recent-posts time {display: block;margin-left: 95px;font-style: italic;font-size: 14px;color: #aaaaaa;}
