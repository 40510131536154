.customclass{

background-color: #e5f1f8;
}

.custombutton{
    padding: 10px;
    width: 250px;
    border-radius: 5px;
    color: white;
    background-color: #038cd1;
    font-weight: 600;
    border: none;
}

.custombutton:hover{
    background-color: #0384c5;
}

.custominput1{
    padding: 10px;
    font-weight: 400;
    width: 250px;
    border-radius: 5px;
    border: none;
 
}



.icon-box i.bi.bi-telephone {
    color: #008bd000;
    text-shadow: #008bd0 0 0 15px;
    transition: 0.4s;
    font-size: 14px !important;
}
.modal-header img {
    max-width: 70%;
    position: relative;
    left: 25%;
}
.icon-box i.bi.bi-geo-alt {
    color: #008bd000;
    text-shadow: #008bd0 0 0 15px;
    transition: 0.4s;
    font-size: 14px !important;
}
.icon-box i.bi.bi-envelope{
    color: #008bd000;
    text-shadow: #008bd0 0 0 15px;
    transition: 0.4s;
    font-size: 14px !important;

}
.modal-header button.btn-close {
    display: flex;
    position: relative;
    top: -20px;
}