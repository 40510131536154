.contact {border:40px solid #ffffff;
  background-color: #e5f1f8;
   
}

.flexdisplay{
  display: flex;
  gap: 10px;

}

.flexdisplay p{
  padding-top: 10px;
}

.contact_link{
 padding-top: 10px; 
}

#herocontact{
    background:url("../../assets/img/NewImages/contact-us-banner.jpg");
    background-position: center center;
    background-size: cover;
}
.contact .info {
  width: 100%;
  background: #fff;
}
.contact .info i {
  font-size: 20px;
  color: #008bd0;
  float: left;
  width: 44px;
  height: 44px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #008bd0;
}
.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #424242;
}
.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .php-email-form {
  width: 100%;
  background: #e5f1f8;
}
.contact .php-email-form .form-group {
  padding-bottom: 8px;
}
.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}
.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}
.contact .php-email-form input {
  height: 44px;
}
.contact .php-email-form textarea {
  padding: 10px 12px;
  resize: none;
}
.contact .php-email-form button[type=submit] {
  background: #008bd0;
  border: 0;
  padding: 10px 40px;
  color: #fff;
  transition: 0.4s;
  border-radius: 0px;
  font-weight: 600;
}
.contact .php-email-form button[type=submit]:hover {
  background: #008bd0;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px 6px 15px;
    position: relative;
    border-radius: 50px;
  }
  
  #footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
  }
  
  #footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #1acc8d;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
  }
  
  #footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #149f6e;
  }
  i.fa.fa-envelope {
    font-size: 20px;
    color: #008bd0;
    float: left;
    width: 44px;
    height: 44px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}
.main-services {
  background-color: #e5f1f8;
  margin-bottom: 25px;
}
section {
  padding: 20px 0 10px 0;
  overflow: hidden;
}
.info i {
  font-size: 20px;
  color: #008bd0;
  float: left;
  width: 44px;
  height: 44px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.main-services .member {
  margin-bottom: 0px;
  position: relative;
  box-shadow: 0px 0px 1px 2px rgba(74, 74, 74, 0.05);
  transition: ease-in-out 0.3s;
  padding: 15px 0;
}
.bg-dblue {
  background-color: #008bd0!important;
}
  

.contacthero{
  background-image: url("../../assets/img/Accidentmate/contact-us.jpg") ;
background-repeat:no-repeat; 
  background-position:  center ;
  background-size: cover;


}

.contacthero h1{
  color: white;
}

.main-services{background-color: #e5f1f8;}



.bg-dblue{background-color: #008bd0!important;}
.inner-contact{background-color: #e5f1f8; border:0;}
.inner-contact .php-email-form, .inner-contact .info{background: transparent;}
.view-btn {border-radius: 0;font-size: 16px;font-weight: 600; background-color: #008bd0;}
.view-btn:hover {background-color: #0180bf;}


.contact {border:40px solid #fff; background-color: #e5f1f8;}
.contact .info {
 width: 100%;
 background: #fff;
}
.info i {
 font-size: 20px;
 color: #008bd0;
 float: left;
 width: 44px;
 height: 44px;
 background: #fff;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius: 50px;
 transition: all 0.3s ease-in-out;
}
.contact .info h4 {
 padding: 0 0 0 60px;
 font-size: 20px;
 font-weight: 600;
 margin-bottom: 5px;
 color: #008bd0;
}
.contact .info p {
 padding: 0 0 0 60px;
 margin-bottom: 0;
 font-size: 14px;
 color: #424242;
}
.contact .info .email,
.contact .info .phone {
 margin-top: 40px;
}


.contact .email-form {
 width: 100%;

}
.contact .email-form .form-group {
 padding-bottom: 8px;
}
.contact .email-form .error-message {
 display: none;
 color: #fff;
 background: #ed3c0d;
 text-align: left;
 padding: 15px;
 font-weight: 600;
}
.contact .email-form .error-message br+br {
 margin-top: 25px;
}
.contact .email-form .sent-message {
 display: none;
 color: #fff;
 background: #18d26e;
 text-align: center;
 padding: 15px;
 font-weight: 600;
}

.contact .email-form .loading {
 display: none;
 background: #fff;
 text-align: center;
 padding: 15px;
}

.contact .email-form .loading:before {
 content: "";
 display: inline-block;
 border-radius: 50%;
 width: 24px;
 height: 24px;
 margin: 0 10px -6px 0;
 border: 3px solid #18d26e;
 border-top-color: #eee;
 -webkit-animation: animate-loading 1s linear infinite;
 animation: animate-loading 1s linear infinite;
}

.contact .email-form input,
.contact .email-form textarea {
 border-radius: 0;
 box-shadow: none;
 font-size: 14px;
}

.contact .email-form input {
 height: 44px;
}

.contact .email-form textarea {
 padding: 10px 12px;
 resize: none;
}

.contact .email-form button[type=submit] {
 background: #008bd0;
 border: 0;
 padding: 10px 40px;
 color: #fff;
 transition: 0.4s;
 border-radius: 0px;
 font-weight: 600;
}

.contact .email-form button[type=submit]:hover {
 background: #008bd0;
}

@-webkit-keyframes animate-loading {
 0% {
   transform: rotate(0deg);
 }

 100% {
   transform: rotate(360deg);
 }
}

@keyframes animate-loading {
 0% {
   transform: rotate(0deg);
 }

 100% {
   transform: rotate(360deg);
 }
}


.form-control .newwwwww{
width:800px;
}

@media only screen and (max-width:767px){
  .contact {border: 10px solid #fff;}
}